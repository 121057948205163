import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg width="362" height="9" viewBox="0 0 362 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M67.0514 8.33102C59.6085 7.42759 5.03778 8.25375 1.06023 7.99818C-2.19413 7.64156 2.80796 1.47805 5.79112 0.568684C6.84578 0.24773 8.08117 0.18235 10.9137 0.354714C13.1736 0.485473 28.7223 0.467639 43.7285 0.402259C58.7347 0.33688 73.1985 0.259614 73.1985 0.259614C73.9518 0.455753 76.2721 -0.180211 76.694 0.0515895C77.3569 0.324995 101.102 0.372544 125.69 0.372544C150.279 0.372544 175.711 0.354711 179.688 0.360654C184.54 0.366598 180.653 0.717274 186.378 0.610289C198.552 0.384432 225.008 0.883691 234.229 0.616229C241.642 0.40226 243.209 0.414147 250.26 0.681609C257.431 0.955015 295.881 0.996621 300.341 0.622174C301.425 0.515189 302.239 0.562737 302.239 0.699439C302.751 0.71727 329.268 1.11549 329.359 1.21653C329.54 1.35324 330.353 1.4305 331.137 1.34135C332.583 1.21059 361.48 1.01445 361.842 1.41862C362.957 2.57762 357.925 7.38004 354.399 8.5331C350.693 9.75154 332.342 8.12894 317.155 8.73518C234.319 8.00412 250.802 8.06356 232.09 8.50338C230.432 8.07544 223.743 9.04425 219.765 8.29536C218.138 7.98629 206.235 7.86742 203.524 8.14677C200.54 8.45584 140.907 7.30277 133.916 8.17054C122.827 7.40976 77.5376 8.19432 67.0514 8.33102Z" fill="#FFBA00"/>
</svg>
  </span>
)

export default titleUnderline
